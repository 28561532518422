import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import { useConnectModal } from '@rainbow-modules/hooks';
import ModalBody from './modal';
import { CUSTOMER_CALL_MODAL } from '../../constants';
import { StyledModal } from './styled';

const CustomerCallModal = () => {
    const connectedModalProps = useConnectModal(CUSTOMER_CALL_MODAL, { title: '' });
    const { customer, onRequestClose } = connectedModalProps;

    return (
        <StyledModal size="small" {...connectedModalProps}>
            <ModalBody
                customer={customer as EntityGet<Customer>}
                onRequestClose={onRequestClose as () => void}
            />
        </StyledModal>
    );
};

export default CustomerCallModal;
