import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';

interface PipelineStageTypePickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

const options = [
    { name: 'open', label: 'Open', value: 'open' },
    { name: 'won', label: 'Closed-Won', value: 'won' },
    { name: 'lost', label: 'Closed-Lost', value: 'lost' },
];

const PipelineStageTypePicker = (props: PipelineStageTypePickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        disabled,
        error,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`pipeline-stage-type-option__${option.name}`} {...option} />),
        [],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            disabled={disabled}
            error={error}
        >
            <Option label="Select type" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default PipelineStageTypePicker;
