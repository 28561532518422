import styled from 'styled-components';
import Button from 'components/Button';
import {
    CARD_SHADOW, GREY_500, ORANGE_500, RED_500, WHITE,
} from '../../constants';
import { Status } from './types';

export const Container = styled.div<{ isExpanded?: boolean }>`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 5rem;
    margin: 1rem auto;
    z-index: 1000;
    display: inline-block;
    align-items: center;
    width: 100%;

    > div:nth-child(n+2) {
        transition: height 0.4s, padding 0.4s;
    }

    ${({ isExpanded }) => !isExpanded && `
        > div:nth-child(n+3) {
            height: 0;
            padding: 0 1rem;
        }
    `}
`;

export const OuterActionsContainer = styled.div<{ isExpanded?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5rem auto;
    width: 75vw;

    opacity: 0;
    transition: opacity 0.4s;
    ${({ isExpanded }) => isExpanded && `
        opacity: 1;
    `}
`;

export const DismissAllButton = styled(Button)`
    box-shadow: ${CARD_SHADOW};
`;

export const ShadowContainer = styled.div<{ isExpanded?: boolean }>`
    position: relative;
    height: 4rem;
    margin: 0.5rem auto;
    width: 75vw;

    ${({ isExpanded }) => !isExpanded && `
        &:nth-child(n+3) {
            opacity: 0;
        }
    `}
`;

export const CallDetailsContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${WHITE};
    box-shadow: ${CARD_SHADOW};
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
`;

export const CallDetailsLabel = styled.span`
    font-size: 1rem;
    font-weight: bold;
    margin-right: 0.5rem;
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    padding-left: 2rem;
    margin-left: auto;
`;

const colorsMap: Record<Status, string> = {
    unknown: GREY_500,
    completed: '#19B332',
    ringing: ORANGE_500,
    'in-progress': '#19B332',
    'no-answer': RED_500,
    failed: GREY_500,
    blocked: GREY_500,
    busy: GREY_500,
    canceled: GREY_500,
    queued: GREY_500,
};

export const CallStatusContainer = styled.div<{ status: Status }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ status }) => colorsMap[status]};
`;
