import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import Phone from 'components/icons/phone';
import SmsIcon from 'components/icons/sms';
import FacebookSolid from 'components/icons/facebookSolid';
import InstagramSolid from 'components/icons/instagramSolid';
import Email from 'components/icons/email';
import RenderIfAdmin from 'components/RenderIfAdmin';
import getInitials from 'data/services/string/getInitials';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import useSMSChat from 'hooks/useSMSChat';
import {
    AvatarContainer,
    NameLabel,
    StyledAvatar,
    QuickActions,
    StyledLink,
    StyledBadgeOverlay,
} from './styled';
import { CUSTOMER_CALL_MODAL } from '../../constants';

interface UserAvatarProps {
    src?: string;
    uid: string;
    displayName?: string;
    phoneNumber?: string;
    email?: string;
    facebookProfileURL?: string;
    instagramProfileURL?: string;
    profileData?: EntityGet<Customer> | null;
}

const UserAvatar = ({
    uid,
    displayName = '',
    src,
    phoneNumber,
    email,
    facebookProfileURL,
    instagramProfileURL,
    profileData,
}: UserAvatarProps) => {
    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);
    const { openChat } = useSMSChat();
    const [openCustomerCallModal] = useOpenModal(CUSTOMER_CALL_MODAL);

    const handleSMSClick = useCallback(
        () => openChat({ ...profileData }),
        [openChat, profileData],
    );

    return (
        <AvatarContainer>
            <StyledAvatar
                src={src}
                assistiveText={displayName}
                initials={initials}
                title={displayName}
                value={uid}
            />
            <NameLabel>
                {displayName}
            </NameLabel>
            <QuickActions>
                <RenderIf isTrue={phoneNumber}>
                    <RenderIfAdmin>
                        <ButtonIcon
                            icon={<Phone />}
                            onClick={() => openCustomerCallModal({ customer: profileData })}
                        />
                    </RenderIfAdmin>
                    <ButtonIcon
                        icon={(
                            <StyledBadgeOverlay
                                overlap="circle"
                                isHidden={!profileData?.hasNewMessage}
                            >
                                <SmsIcon />
                            </StyledBadgeOverlay>
                        )}
                        onClick={handleSMSClick}
                    />
                </RenderIf>
                <RenderIf isTrue={email}>
                    <StyledLink as="a" href={`mailto:${email}`}>
                        <Email />
                    </StyledLink>
                </RenderIf>
                <RenderIf isTrue={facebookProfileURL}>
                    <StyledLink to={String(facebookProfileURL)} target="_blank">
                        <FacebookSolid />
                    </StyledLink>
                </RenderIf>
                <RenderIf isTrue={instagramProfileURL}>
                    <StyledLink to={String(instagramProfileURL)} target="_blank">
                        <InstagramSolid />
                    </StyledLink>
                </RenderIf>
            </QuickActions>
        </AvatarContainer>
    );
};

export default UserAvatar;
