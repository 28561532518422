import { IconProps } from 'types';

const PhoneInbound = ({ className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        viewBox="0 0 22 22"
        className={className}
    >
        {title && <title>{title}</title>}
        <path
            stroke="#88888D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m13.75 8.25 4.583-4.583M13.75 4.583V8.25h3.667"
        />
        <path
            stroke="#88888D"
            strokeWidth={2}
            d="m2.844 7.925.003.008.002.008c.582 1.668 1.819 4.172 4.43 6.784 2.61 2.61 5.114 3.849 6.784 4.426l.007.003.007.002a3.54 3.54 0 0 0 3.621-.87c.433-.426.895-.889 1.235-1.229a1.364 1.364 0 0 0 0-1.928l-2.607-2.608a1.364 1.364 0 0 0-1.928 0l-1.267 1.267a.09.09 0 0 1-.106.016A16.46 16.46 0 0 1 8.2 8.974a.088.088 0 0 1 .016-.1l.003-.005c.353-.356.875-.878 1.263-1.266a1.364 1.364 0 0 0 0-1.929L6.875 3.067a1.364 1.364 0 0 0-1.928 0L3.705 4.309a3.545 3.545 0 0 0-.861 3.616Z"
        />
    </svg>
);
export default PhoneInbound;
