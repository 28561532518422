import { isEmpty } from '@rainbow-modules/validation';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const toolSchema = {
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: ['function'],
        },
        function: {
            additionalProperties: false,
            properties: {
                description: {
                    type: 'string',
                    minLength: 1,
                },
                name: {
                    type: 'string',
                    minLength: 1,
                },
                parameters: {
                    type: 'object',
                    properties: {
                        type: {
                            enum: ['object'],
                            type: 'string',
                        },
                        properties: {
                            type: 'object',
                        },
                        required: {
                            type: 'array',
                            items: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            type: 'object',
            required: ['name', 'description'],
        },
    },
    type: 'object',
    required: ['type', 'function'],
};

const toolsListSchema = {
    items: toolSchema,
    type: 'array',
    minItems: 0,
};

const validateTool = (value?: string | Record<string, unknown>) => {
    const errorMessage = 'Invalid Tool definition';
    if (value) {
        try {
            const json = (
                typeof value === 'string'
                    ? JSON.parse(value)
                    : value
            );

            const ajv = new Ajv({ allErrors: true });
            addFormats(ajv);
            const isValidToolsJson = ajv.validate(toolSchema, json);
            if (!isValidToolsJson) return ajv.errorsText(ajv.errors) || errorMessage;
            const parameters = json?.function?.parameters;
            const areToolsValid = Object.keys(parameters?.properties || {}).reduce(
                (result, parameterName) => {
                    if (!result || isEmpty(parameterName)) return false;
                    const parameter = (parameters?.properties || {})[parameterName];
                    const isValidParam = ajv.validateSchema(parameter);
                    return isValidParam === true;
                },
                true,
            );
            if (areToolsValid) return undefined;
        } catch (error) {
            // no catch
        }
    }
    return errorMessage;
};

const validateTools = (value?: string | Array<Record<string, unknown>>) => {
    const errorMessage = 'Invalid Tools definition';
    if (value) {
        try {
            const json = (
                typeof value === 'string'
                    ? JSON.parse(value)
                    : value
            );

            const ajv = new Ajv({ allErrors: true });
            addFormats(ajv);
            const isValidToolsList = ajv.validate(toolsListSchema, json);
            if (isValidToolsList) {
                // check valid parameters
                const tools = (json as Array<Record<string, unknown>>);
                const toolValid = tools.map((tool) => validateTool(tool));
                const hasInvalidTool = toolValid.some((toolValidResult) => typeof toolValidResult === 'string');
                if (!hasInvalidTool) {
                    const functionNames = Array.from(
                        new Set(
                            tools.map(
                                (tool) => (
                                    (tool.function || {}) as Record<string, unknown>
                                ).name as string,
                            ),
                        ),
                    );
                    if (functionNames.length === tools.length) return undefined;
                    return 'There are duplated tools';
                }
                return toolValid.filter((toolValidResult) => typeof toolValidResult === 'string').join(', ');
            }
            return ajv.errorsText(ajv.errors) || errorMessage;
        } catch (error) {
            // no catch
        }
    }
    return errorMessage;
};

export default validateTools;
