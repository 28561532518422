import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Provider } from './context';

interface Props {
    children: ReactNode;
}

const OutboundCallProvider = ({ children }: Props) => {
    const [trackedCalls, setTrackedCalls] = useState<string[]>([]);

    const addOutboundCall = useCallback(async (sessionSid: string) => {
        setTrackedCalls((calls) => [...calls, sessionSid]);
    }, []);

    const dismissOutboundCall = useCallback(async (sessionSid: string) => {
        setTrackedCalls((calls) => calls.filter((call) => call !== sessionSid));
    }, []);

    const dismissAllOutboundCalls = useCallback(async () => {
        setTrackedCalls([]);
    }, []);

    const value = useMemo(() => ({
        addOutboundCall,
        trackedCalls,
        dismissOutboundCall,
        dismissAllOutboundCalls,
    }), [addOutboundCall, dismissAllOutboundCalls, dismissOutboundCall, trackedCalls]);

    return (
        <Provider value={value}>
            {children}
        </Provider>
    );
};

export default OutboundCallProvider;
