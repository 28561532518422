import { useRef } from 'react';
import { InternalTooltip, RenderIf, useDefaultTooltipConnector } from 'react-rainbow-components';
import { ItemUser } from 'data/firestore/globals';
import AvatarsGroup from 'components/AvatarsGroup';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayNameForParticipant from './helpers/getDisplayNameForParticipant';
import { CustomerInfoContainer, StyledListTile, StyledPhoneNumber } from './styled';
import CustomerInfo from './CustomerInfo';

interface Props {
    customers: ItemUser[];
    variant?: 'default' | 'horizontal';
}

const CustomersInfo = ({ customers, variant = 'default' }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef();
    const {
        onMouseEnter,
        onMouseLeave,
        isVisible,
    } = useDefaultTooltipConnector({
        tooltipRef,
        triggerRef: () => containerRef as any,
    });

    const customerDisplayName = getDisplayNameForParticipant(customers);
    const avatar = (
        <AvatarsGroup
            users={customers}
            expandOnHover
        />
    );
    const phoneNumber = customers.at(0)?.phoneNumber;
    const shouldShowTooltip = isVisible && customers.length > 1;

    return (
        <CustomerInfoContainer
            ref={containerRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <StyledListTile
                icon={avatar}
                title={customerDisplayName}
                subtitle={variant === 'default' && formatIntlPhoneNumber((phoneNumber as string) || '')}
            />
            <RenderIf isTrue={variant === 'horizontal'}>
                <StyledPhoneNumber>
                    {formatIntlPhoneNumber(phoneNumber)}
                </StyledPhoneNumber>
            </RenderIf>
            <InternalTooltip
                triggerElementRef={() => containerRef}
                ref={tooltipRef}
                preferredPosition="bottom"
                isVisible={shouldShowTooltip}
            >
                <CustomerInfo customerData={customers} />
            </InternalTooltip>
        </CustomerInfoContainer>
    );
};

export default CustomersInfo;
