import { ReactNode } from 'react';
import PhoneCall from 'components/icons/phoneCall';
import PhoneAnswered from 'components/icons/phoneAnswered';
import Close from 'components/icons/close';
import { CallStatusContainer } from './styled';
import { Status } from './types';

interface Props {
    status?: Status;
}

const statusMap: Record<Status, string> = {
    unknown: 'Unknown',
    completed: 'Completed',
    ringing: 'Ringing',
    'in-progress': 'In Progress',
    'no-answer': 'No Answer',
    failed: 'Failed',
    blocked: 'Blocked',
    busy: 'Busy',
    canceled: 'Canceled',
    queued: 'Queued',
};

const iconsMap: Record<Status, ReactNode> = {
    unknown: <span />,
    completed: <PhoneAnswered />,
    ringing: <PhoneCall />,
    'in-progress': <PhoneAnswered />,
    'no-answer': <Close />,
    failed: <Close />,
    blocked: <Close />,
    busy: <Close />,
    canceled: <Close />,
    queued: <Close />,
};

const CallStatus = ({ status = 'unknown' }: Props) => (
    <CallStatusContainer status={status}>
        {iconsMap[status]}
        {statusMap[status] || 'Unknown Status'}
    </CallStatusContainer>
);

export default CallStatus;
