import PhoneInbound from 'components/icons/phoneInbound';
import PhoneOutbound from 'components/icons/phoneOutbound';
import { CellWrapper } from '../styled';
import { DirectionLabel } from './styled';

interface Props {
    value?: 'inbound' | 'outbound';
}

const DirectionColumn = ({ value }: Props) => {
    const Icon = value === 'inbound' ? PhoneInbound : PhoneOutbound;
    return (
        <CellWrapper>
            <Icon />
            {' '}
            <DirectionLabel>{value}</DirectionLabel>
        </CellWrapper>
    );
};

export default DirectionColumn;
