import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import ButtonIcon from 'components/ButtonIcon';
import PhoneIcon from 'components/icons/phone';
import SmsIcon from 'components/icons/sms';
import useCustomerPanel from 'hooks/useCustomerPanel';
import { Customer } from 'data/firestore/agent/customer/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import useSMSChat from 'hooks/useSMSChat';
import RenderIfAdmin from 'components/RenderIfAdmin';
import { useOpenModal } from '@rainbow-modules/hooks';
import {
    CustomerAction, CustomerAvatar, CustomerBubble, CustomerInfoContainer,
    CustomerName, CustomerSmsBadgeOverlay, CustomerButton, CustomerLoading,
} from './styled';
import { CUSTOMER_CALL_MODAL } from '../../../../constants';

interface CustomerInfoProps {
    customer?: Customer | null;
    hideActions?: boolean;
    isLoading?: boolean;
}

const CustomerInfo = ({
    customer,
    hideActions = false,
    isLoading,
}: CustomerInfoProps) => {
    const { openCustomerPanel } = useCustomerPanel();

    const customerDisplayName = useMemo(
        () => getDisplayName(customer, formatIntlPhoneNumber(customer?.phoneNumber as string)),
        [customer],
    );

    const { openChat } = useSMSChat();
    const [openCustomerCallModal] = useOpenModal(CUSTOMER_CALL_MODAL);

    const handleSMSClick = useCallback(
        () => openChat({ ...customer }),
        [openChat, customer],
    );

    return (
        <CustomerInfoContainer>
            <RenderIf isTrue={isLoading && !customer}>
                <CustomerLoading>
                    <LoadingShape shape="circle" width="48px" height="48px" />
                    <LoadingShape shape="rounded-rect" width="200px" height="20px" />
                </CustomerLoading>
            </RenderIf>
            <RenderIf isTrue={customer}>
                <CustomerButton onClick={() => openCustomerPanel({ data: customer })}>
                    <CustomerAvatar
                        size="large"
                        initials={getInitials(customer as unknown as Record<string, unknown>)}
                        src={customer?.photoURL}
                        value={customer?.id}
                    />
                    <CustomerName>{customerDisplayName}</CustomerName>
                </CustomerButton>
                <RenderIf isTrue={customer?.phoneNumber && !hideActions}>
                    <CustomerBubble>
                        <RenderIfAdmin>
                            <CustomerAction
                                as={ButtonIcon}
                                icon={<PhoneIcon />}
                                onClick={() => openCustomerCallModal({ customer })}
                            />
                        </RenderIfAdmin>
                        <ButtonIcon
                            icon={(
                                <CustomerSmsBadgeOverlay
                                    overlap="circle"
                                    isHidden={!customer?.hasNewMessage}
                                >
                                    <SmsIcon />
                                </CustomerSmsBadgeOverlay>
                            )}
                            onClick={handleSMSClick}
                        />
                    </CustomerBubble>
                </RenderIf>
            </RenderIf>
        </CustomerInfoContainer>
    );
};

export default CustomerInfo;
