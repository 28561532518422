import styled, { css } from 'styled-components';
import Card from 'components/Card';
import InstructionsEditor from 'components/InstructionsEditor';
import ButtonIcon from 'components/ButtonIcon';
import {
    GREY_800, PURPLE_500, PURPLE_100, GREY_500, GREY_400, DIVIDER, GREY_200, GREY_100,
} from '../../constants';

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    gap: 1rem;
    height: calc(100vh - 160px);
`;

export const InstructionsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 0 0.5rem 0;
    flex: 3;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
    padding: 0 1rem 1.25rem 1.5rem;
`;

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_400};
`;

export const HeaderActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
`;

export const InstructionsInputContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid ${DIVIDER};
`;

export const InstructionsInputContent = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const StyledSidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    overflow: auto;
    padding: 1rem;
    border-right: 1px solid ${DIVIDER};
    gap: 0.25rem;
`;

export const MenuItemContainer = styled.li<{ isActive?: boolean; }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    width: 100%;
    color: ${GREY_500};
    padding: 0.6rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    letter-spacing: 0.02688rem;
    transition: background-color 0.2s ease-in-out;
    font-weight: 300;
    gap: 0.5rem;

    > span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover, &:focus, &:active {
        color: ${PURPLE_500};
        text-decoration: none;
        background-color: ${PURPLE_100};
        cursor: pointer;
    }

    ${(props) => props.isActive && css`
        color: ${PURPLE_500};
        text-decoration: none;
        background-color: ${PURPLE_100};
        cursor: pointer;
    `}
`;

export const InstructionsInput = styled(InstructionsEditor)`
    height: 100%;
    flex-grow: 1;
    border: none;
    border-radius: 0;
    padding-top: 0.5rem;
`;

export const StyledFooter = styled.footer`
    border-top: 1px solid ${DIVIDER};
    display: flex;
    padding: 0.5rem 0.5rem 0 0.75rem;
    justify-content: space-between;
    flex: 1;
`;

export const Label = styled.span`
    color: ${GREY_800}
    flex-grow: 1;
    line-height: 1.375rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
`;

export const LeftButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const HelperContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 1.5rem;
    flex: 1;
    min-width: 18rem;
    max-width: 20rem;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const HeaderWithActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    background-color: ${GREY_100};
    border: 1px solid transparent;
`;

export const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 100%;
`;

export const HistoryHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
`;

export const Date = styled.div`
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_400};
`;

interface TimelineProps {
    isActive?: boolean;
}
export const TimelineContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<TimelineProps>`
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    width: 100%;
    border-radius: 0.375rem;

    &:hover {
        background-color: rgb(248, 247, 250);
    }

    ${(props) => props.isActive && css`
        background-color: ${PURPLE_100};

        &:hover {
            background-color: ${PURPLE_100};
        }
    `}
`;

export const TimelineTime = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<TimelineProps>`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${GREY_400};
    padding: 1.2rem 0 0 0;
    max-width: 4rem;
    min-width: 4rem;
    text-align: right;

    ${(props) => props.isActive && css`
        color: ${GREY_800};
        font-weight: 500;
    `}
`;

export const Timeline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 0%;
    padding: 0 1rem;
`;

export const TimelineCircle = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<TimelineProps>`
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: ${GREY_200};
    border: 0.1875rem solid #E1E1E3;
    box-sizing: content-box;
    flex-shrink: 1;

    ${(props) => props.isActive && css`
        background-color: ${PURPLE_500};
        border: 0.1875rem solid #CAC6FA;
    `}
`;

export const TimelineContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    overflow: hidden;
    flex-shrink: 1;
`;

export const ModifyBy = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<TimelineProps>`
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 1;

    ${(props) => props.isActive && css`
        color: ${GREY_800};
        font-weight: 500;
    `}
`;

export const Version = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<TimelineProps>`
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.3125rem;
    color: ${GREY_400};

    ${(props) => props.isActive && css`
        color: ${GREY_800};
        font-weight: 500;
    `}
`;

export const Divider = styled.div`
    width: 1px;
    flex-grow: 1;
    background: ${DIVIDER};
`;

export const TopDivider = styled.div`
    width: 1px;
    background: ${DIVIDER};
    height: 1.2rem;
`;

export const SidebarLabel = styled.span`
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.9375rem;
    text-transform: uppercase;
    padding: 1rem 1rem 0.6rem 1rem;
    color: ${GREY_200};
`;

export const FooterButton = styled(ButtonIcon)`
    color: ${PURPLE_500};
    svg {
        width: 1.25rem !important;
        height: 1.25rem !important;
        font-size: 1.25rem !important;
    }
`;
