import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { query, where } from 'firebase/firestore';
import useSession from 'data/firestore/session/use';
import useCustomers from 'data/firestore/agent/customer/useCollection';
import CustomersInfo from 'components/CustomersInfo';
import Button from 'components/Button';
import formatTime from 'helpers/time/formatTime';
import { CallDetailsContainer, CallDetailsLabel, ActionsContainer } from './styled';
import CallStatus from './callStatus';

interface Props {
    callSid: string;
    onShowDetails?: () => void;
    onDismiss?: () => void;
}

const CallDetails = ({ callSid, onShowDetails, onDismiss }: Props) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();

    const { data: session } = useSession(callSid);
    const { data: customers } = useCustomers(agentId, {
        listQueryFn: (ref) => query(ref, where('phoneNumber', '==', session?.to)),
        disabled: !session || !session.to,
    });

    const [time, setTime] = useState(0);

    const mappedCustomers = (customers || []).map((c) => ({ ...c, role: 'customer' as const }));
    const { status } = session || {};

    useEffect(() => {
        let timer: NodeJS.Timer;
        if (status === 'in-progress') {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000); // Increment every second
        }

        // Clean up the interval on component unmount or when timer stops
        return () => clearInterval(timer);
    }, [status]);

    return (
        <CallDetailsContainer>
            <CallDetailsLabel>Call with:</CallDetailsLabel>
            <CustomersInfo customers={mappedCustomers} variant="horizontal" />
            <ActionsContainer>
                <CallStatus status={session?.status} />
                <RenderIf isTrue={status === 'in-progress' || status === 'completed'}>
                    {formatTime(time)}
                </RenderIf>
                <Button variant="outline-brand" borderRadius="semi-rounded" onClick={onShowDetails}>
                    Show Call Details
                </Button>
                <Button variant="border" borderRadius="semi-rounded" onClick={onDismiss}>
                    Dissmiss
                </Button>
            </ActionsContainer>
        </CallDetailsContainer>
    );
};

export default CallDetails;
