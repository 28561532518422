import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const validateJsonSchema = (value?: string | { [key: string]: unknown }) => {
    if (!value) return undefined;
    const errorMessage = 'Invalid JSON Schema';
    try {
        const json = (
            typeof value === 'string'
                ? JSON.parse(value)
                : value
        );
        if (
            Object.keys(json).length === 0
            || json.type !== 'object'
            || !json.properties
        ) {
            return errorMessage;
        }

        const ajv = new Ajv({ allErrors: true });
        addFormats(ajv);
        const isValid = ajv.validateSchema(json);
        if (isValid) return undefined;
        return ajv.errorsText(ajv.errors) || errorMessage;
    } catch (error) {
        // no catch
    }
    return errorMessage;
};

export default validateJsonSchema;
