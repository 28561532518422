import styled, { css } from 'styled-components';
import { Badge } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import { GREY_800 } from '../../../../constants';

export const BoardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 1rem 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
`;

export const SummaryContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const SummaryLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
`;

export const PriceLabel = styled.span`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
`;

export const StageInfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const StageColor = styled.div<{ color: string; }>`
    display: flex;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0rem 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 2px solid ${(props) => props.color};
    background: ${(props) => props.color}26;
`;

export const StageTitle = styled.h3<{ isBuiltin?: boolean; }>`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem; 

    ${(props) => props.isBuiltin && css`
        text-transform: capitalize;
    `};
`;

export const StageCardsCount = styled(Badge)`
    background: #E7E7E7;
    padding: 0.25rem;
    height: 1.125rem;
    box-sizing: border-box;

    & > span {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
    }
`;

export const StyledUserAvatar = styled(UserAvatar)`
    width: 1.75rem;
    height: 1.75rem;

    & abbr {
        font-size: 0.75rem;
    }
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 3rem 4rem;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
`;

export const EmptyMessageEmoji = styled.h2`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    max-width: 500px;
    line-height: 1.5rem;
    color: ${GREY_800};
`;
