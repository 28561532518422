import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import styled from 'styled-components';
import { color } from 'theme';

export const StyledSingleUserAvatar = styled(UserAvatar)`
    border: 2px solid transparent;
    border: 2px solid ${color('background.main')};
    pointer-events: none;
`;

export const CustomerInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledPhoneNumber = styled.span`
    color: ${color('text.disabled')};
    margin-left: 0.5rem;
    white-space: nowrap;
`;

export const StyledListTile = styled(ListTile)`
    width: auto;
`;
