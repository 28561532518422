import { ReactNode } from 'react';
import useUserRoles from 'hooks/useUserRoles';

interface Params {
    children?: ReactNode;
}

const RenderIfAdmin = ({ children }: Params) => {
    const { isAdmin } = useUserRoles();
    if (isAdmin) {
        return children;
    }
    return null;
};

export default RenderIfAdmin;
