import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import context from 'components/OutboundCallProvider/context';
import { BottomShadow } from 'components/TasksInCategory/Task/styled';
import {
    Container, DismissAllButton, OuterActionsContainer, ShadowContainer,
} from './styled';
import CallDetails from './callDetails';

const OutboundCallsNotification = () => {
    const { agentId = '' } = useParams<{ agentId?: string }>();

    const { trackedCalls = [] } = useContext(context);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const { dismissOutboundCall, dismissAllOutboundCalls } = useContext(context);

    const handleMouseEnter = () => {
        setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        setIsExpanded(false);
    };

    const navigateToCallDetails = (sessionSid: string) => {
        navigate(`/${agentId}/voip/history/${sessionSid}`);
    };

    return (
        <RenderIf isTrue={trackedCalls.length > 0}>
            <Container
                isExpanded={isExpanded}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <OuterActionsContainer isExpanded={isExpanded}>
                    <DismissAllButton
                        variant="neutral"
                        borderRadius="rounded"
                        onClick={dismissAllOutboundCalls}
                    >
                        Dissmiss All
                    </DismissAllButton>
                </OuterActionsContainer>
                {trackedCalls.map((sessionSid) => (
                    <ShadowContainer isExpanded={isExpanded}>
                        <CallDetails
                            key={sessionSid}
                            callSid={sessionSid}
                            onShowDetails={() => navigateToCallDetails(sessionSid)}
                            onDismiss={() => dismissOutboundCall(sessionSid)}
                        />
                        <RenderIf isTrue={!isExpanded && trackedCalls.length > 1}>
                            <BottomShadow />
                        </RenderIf>
                    </ShadowContainer>
                ))}
            </Container>
        </RenderIf>
    );
};

export default OutboundCallsNotification;
