import {
    ChangeEvent, MutableRefObject, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { ButtonIcon } from 'react-rainbow-components';
import Play from 'components/icons/play';
import Pause from 'components/icons/pause';
import { VolumeInput, RateInput } from 'components/Player';
import formatTime from 'helpers/time/formatTime';
import {
    StyledControls,
    StyledVideo,
    StyledVideoContainer,
    StyledPlayContainer,
    StyledInputRange,
    StyledPlayButton,
    StyledTime,
} from './styled';

interface Props {
    url?: string | null;
}

const VideoPlayer = ({ url }: Props) => {
    const playerRef = useRef<HTMLVideoElement>();
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [play, setPlay] = useState<boolean>(false);
    const [playing, setPlaying] = useState<boolean>(true);
    const [volume, setVolume] = useState<number>(1);
    const [rate, setRate] = useState<number>(1);
    const [height, setHeight] = useState<number>(212);

    const duration = playerRef.current?.duration || 0;
    const PlayPauseIcon = playing ? Play : Pause;

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        if (playing) {
            playerRef.current?.pause();
        } else {
            playerRef.current?.play();
        }
    }, [playing]);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.currentTime = currentTime;
        }
    }, [currentTime]);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.playbackRate = rate;
        }
    }, [rate]);

    const onSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
        const seconds = event.target.value as unknown as number;
        setCurrentTime(seconds);
    };

    const onLoadedMetadata = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
        const target = event.target as HTMLVideoElement;
        setHeight((target.videoHeight * 172) / target.videoWidth);
    };

    const onTimeUpdate = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
        const target = event.target as HTMLVideoElement;
        setCurrentTime(Math.floor(target.currentTime));
    };

    const onPlay = () => {
        setPlay(true);
        setPlaying(false);
    };

    const onEnded = () => {
        setPlaying(true);
        setCurrentTime(0);
    };

    return (
        <StyledVideoContainer>
            <StyledVideo
                ref={playerRef as MutableRefObject<HTMLVideoElement>}
                src={url as string}
                width={172}
                height={height}
                onLoadedMetadata={onLoadedMetadata}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}
            />
            {play ? (
                <StyledControls>
                    <StyledPlayButton
                        size="x-small"
                        icon={<PlayPauseIcon />}
                        variant="border-filled"
                        onClick={() => setPlaying(!playing)}
                    />
                    <StyledTime>{formatTime(currentTime)}</StyledTime>
                    <StyledInputRange
                        type="range"
                        value={currentTime}
                        onChange={onSliderChange}
                        min={0}
                        max={duration}
                    />
                    <StyledTime>
                        {`-${formatTime(duration - currentTime)}`}
                    </StyledTime>
                    <VolumeInput value={volume} onChange={setVolume} dark />
                    <RateInput value={rate} onChange={setRate} dark />
                </StyledControls>
            ) : (
                <StyledPlayContainer>
                    <ButtonIcon icon={<Play />} variant="border-filled" onClick={onPlay} />
                </StyledPlayContainer>
            )}
        </StyledVideoContainer>
    );
};

export default VideoPlayer;
