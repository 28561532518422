import {
    useEffect, useMemo, useState, Dispatch, SetStateAction,
} from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from 'components/SideBar';
import TopBar from 'components/TopBar';
import SMSChat from 'components/SMSChat';
import CustomerPanelModal from 'components/CustomerPanel';
import OutboundCallModal from 'components/OutboundCallsNotification';
import useGenerateSearchKey from 'data/hooks/useGenerateSearchKey';
import useIdentifyUserInAnalytics from 'analytics/useIdentifyUserInAnalytics';
import { useConnectModal } from '@rainbow-modules/hooks';
import { Provider, Context } from 'context';
import CustomerCallModal from 'components/CustomerCallModal';
import { CUSTOMER_PANEL_DRAWER, SMS_CHAT_DRAWER } from '../../constants';
import {
    Container, Content, OutletContainer, TopBarContainer, ModalStyle,
} from './styled';

const initialState = {
    sessions: undefined,
    inbox: undefined,
};

type ReturnType = [Context['algoliaSecureKeys'], Dispatch<SetStateAction<Context['algoliaSecureKeys']>>];

const useAlgoliaSecretKey = (): ReturnType => {
    const [keys, setKeys] = useState<Context['algoliaSecureKeys']>(initialState);
    const { mutateAsync } = useGenerateSearchKey();

    useEffect(() => {
        const indexNames = [
            'sessions',
            'inbox',
            // 'inbox_createdAt_desc',
            // 'inbox_createdAt_asc',
            // 'inbox_priority_desc',
            // 'inbox_priority_asc',
            'blockednumbers',
            'customers',
            'affiliates',
        ];
        indexNames.forEach((indexName) => {
            (async () => {
                const { algoliaSecureKey } = await mutateAsync({
                    body: {
                        indexName,
                    },
                });
                setKeys((prevKeys) => ({
                    ...prevKeys,
                    [indexName]: algoliaSecureKey,
                }));
            })();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => [keys, setKeys], [keys, setKeys]);
};

const DasboardLayout = () => {
    const [keys, setAlgoliaSecureKeys] = useAlgoliaSecretKey();
    const connectedSMSChatProps = useConnectModal(SMS_CHAT_DRAWER, { isOpen: false });
    const connectedCustomerPanelProps = useConnectModal(CUSTOMER_PANEL_DRAWER, { isOpen: false });

    const contextValue = useMemo(() => ({
        smsChatProps: connectedSMSChatProps,
        customerPanelProps: connectedCustomerPanelProps,
        algoliaSecureKeys: keys,
        setAlgoliaSecureKeys,
    }), [connectedSMSChatProps, connectedCustomerPanelProps, keys, setAlgoliaSecureKeys]);

    useIdentifyUserInAnalytics();

    return (
        <>
            <ModalStyle
                smsChatOpen={connectedSMSChatProps.isOpen}
                customerPanelOpen={connectedCustomerPanelProps.isOpen}
            />
            <Provider value={contextValue}>
                <Container>
                    <SideBar />
                    <Content>
                        <TopBarContainer>
                            <TopBar />
                        </TopBarContainer>
                        <OutletContainer>
                            <Outlet />
                        </OutletContainer>
                    </Content>
                    <SMSChat />
                    <CustomerPanelModal />
                    <CustomerCallModal />
                    <OutboundCallModal />
                </Container>
            </Provider>
        </>
    );
};

export default DasboardLayout;
