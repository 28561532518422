import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    orderBy, query, where,
} from 'firebase/firestore';
import { Column } from 'react-rainbow-components';
import useSessions from 'data/firestore/session/useCollectionWithPagination';
import DurationColumn from 'components/DurationColumn';
import DateTimeColumn from 'components/DateTimeColumn';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { ALGOLIA_INDEX_SESSIONS, PAGE_SIZE } from '../../../constants';
import {
    Container, EntriesText, PaginationContainer,
} from './styled';
import ConversationIdColumn from './columns/conversationId';
import UserColumn from './columns/user';
import StatusColumn from './columns/status';
import DirectionColumn from './columns/direction';

interface ConversationsListProps {
    search?: string;
    dateFilter?: Date[];
}

const buildAlgoliaFilters = (
    agentId: string,
    dates: Date[] = [],
) => {
    const filters = [];

    if (agentId) {
        filters.push(`agentId:${agentId}`);
    }

    if (dates.length > 1) {
        const [from, to] = dates;
        filters.push(`createdAt:${from.getTime()} TO ${to.getTime()}`);
    }

    return filters.join(' AND ');
};

const ConversationsList = ({ search, dateFilter = [] }: ConversationsListProps) => {
    const [activePage, setActivePage] = useState(1);

    const { agentId = '' } = useParams<{ agentId: string }>();
    const {
        data: sessions,
        isLoading: isLoadingSessions,
        totalRecords: totalSessions,
        nextPage,
        prevPage,
        error,
    } = useSessions({
        limit: PAGE_SIZE,
        listQueryFn: (ref) => query(ref, where('agentId', '==', agentId), orderBy('createdAt', 'desc')),
        track: [agentId],
    });

    useEffect(() => setActivePage(1), [search, dateFilter]);

    const algoliaData = useAlgoliaSearchData({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_SESSIONS,
        filters: buildAlgoliaFilters(agentId, dateFilter),
        enabled: Boolean(search || dateFilter.length > 0),
    });

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingSessions) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    };

    if (error || (!sessions && !isLoadingSessions)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }
    const dataToShow = algoliaData?.hits || sessions;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalSessions;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    return (
        <Container>
            <Table
                data={dataToShow}
                keyField="id"
                isLoading={isLoadingSessions}
            >
                <Column header="Conversation ID" field="id" component={ConversationIdColumn} defaultWidth={220} />
                <Column header="Direction" field="direction" component={DirectionColumn} />
                <Column header="Caller" component={UserColumn} />
                <Column header="Date" field="createdAt" component={DateTimeColumn} />
                <Column header="Duration" field="duration" component={DurationColumn} cellAlignment="right" />
                <Column header="Status" field="status" component={StatusColumn} defaultWidth={140} />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default ConversationsList;
