import { useCallback, useContext } from 'react';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import context from 'components/OutboundCallProvider/context';

interface Props {
    agentId: string;
    customerId: string;
}

interface Req {
    personaId: string;
}

interface Res {
    code: string;
    message: string;
    callSid: string;
}

const useCallCustomer = ({ agentId, customerId }: Props) => {
    const { mutateAsync } = useHttpMutation<Req, Res>({
        method: 'POST',
        pathname: `/agents/${agentId}/customers/${customerId}/call`,
    });
    const { addOutboundCall } = useContext(context);

    const callCustomer = useCallback(async (responderId: string) => {
        try {
            showAppSpinner();
            const { callSid } = await mutateAsync({
                body: {
                    personaId: responderId,
                },
            });
            addOutboundCall(callSid);
        } catch (error) {
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [addOutboundCall, mutateAsync]);

    return {
        callCustomer,
    };
};

export default useCallCustomer;
