import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import Diamonds from 'components/icons/diamonds';
import Phone from 'components/icons/phone';
import { Customer } from 'data/firestore/agent/customer/types';
import useResponders from 'data/firestore/agent/llm/useCollection';
import { EntityGet } from 'data/firestore/types';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { or, query, where } from 'firebase/firestore';
import useCallCustomer from 'hooks/useCallCustomer';
import { useMemo, useState } from 'react';
import { Option } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import Script from 'components/icons/script';
import {
    Container, CustomerInfoContainer, NameLabel,
    PhoneLabel, StyledAvatar, StyledCallNowButton, StyledPicklist,
} from './styled';

interface CustomerCallModalProps {
    customer: EntityGet<Customer>;
    onRequestClose: () => void;
}

const ModalBody = ({ customer, onRequestClose }: CustomerCallModalProps) => {
    const { agentId = '' } = useParams();
    const {
        id, photoURL, phoneNumber,
    } = customer;
    const displayName = getDisplayName(customer);

    const [caller] = useState({
        value: 'sara',
        label: 'Sara AI Assistant',
        icon: <Diamonds />,
    });
    const [responder, setResponder] = useState<PicklistValue>({
        value: '',
        label: '',
        icon: <Script />,
    });
    const [responderError, setResponderError] = useState<string | null>(null);
    const { data: responders, isLoading: isLoadingResponders } = useResponders(agentId, {
        listQueryFn: (ref) => query(ref, or(
            where('featureSchema', '==', 'system.call.incoming'),
            where('featureSchema', '==', 'system.call.outbound'),
        )),
    });
    const { callCustomer } = useCallCustomer({ agentId, customerId: id });

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const handleResponderChange = (value: PicklistValue) => {
        setResponder(value);
        setResponderError(null);
    };

    const handleCallNow = async () => {
        if (responder.value) {
            await callCustomer(responder.value);
            onRequestClose();
            return;
        }
        setResponderError('This field is required');
    };

    return (
        <Container>
            <CustomerInfoContainer>
                <StyledAvatar
                    src={photoURL}
                    assistiveText={displayName}
                    initials={initials}
                    title={displayName}
                    value={id}
                />
                <NameLabel>
                    {displayName}
                </NameLabel>
                <PhoneLabel>
                    {formatIntlPhoneNumber(phoneNumber)}
                </PhoneLabel>
            </CustomerInfoContainer>
            <StyledPicklist
                label="Who should make this call?"
                labelAlignment="left"
                value={caller}
                readOnly
            >
                <Option value="sara" label="Sara AI Assistant" icon={<Diamonds />} />
            </StyledPicklist>
            <StyledPicklist
                label="Sara AI Call Script"
                labelAlignment="left"
                placeholder="Select AI Call Script"
                value={responder}
                onChange={handleResponderChange}
                isLoading={isLoadingResponders}
                error={responderError}
            >
                {responders?.map((r) => (
                    <Option
                        key={r.id}
                        name={r.name}
                        label={r.name}
                        value={r.id}
                        icon={<Script />}
                    />
                ))}
            </StyledPicklist>
            <StyledCallNowButton
                label="Add Customer"
                variant="brand"
                size="medium"
                borderRadius="semi-rounded"
                onClick={handleCallNow}
            >
                <Phone className="rainbow-m-right_x-small" />
                Call Now
            </StyledCallNowButton>
        </Container>
    );
};

export default ModalBody;
