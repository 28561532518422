import { IconProps } from 'types';

const Script = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
    >
        {title && <title>{title}</title>}
        <path
            stroke="#88888D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 5h8M13 9h5M13 15h8M13 19h5"
        />
        <rect
            width={5}
            height={5}
            x={4}
            y={14.5}
            stroke="#88888D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            rx={1}
        />
        <path
            fill="#88888D"
            stroke="#88888D"
            d="M2.75 9.78a.25.25 0 1 1 0-.5.25.25 0 0 1 0 .5Z"
        />
        <path
            stroke="#88888D"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5.788 4.209a.375.375 0 0 1 .673 0l.186.376c.316.64.773 1.198 1.338 1.634l.38.294a.375.375 0 0 1 0 .594l-.38.293a4.686 4.686 0 0 0-1.338 1.634l-.186.377a.375.375 0 0 1-.673 0l-.186-.377A4.687 4.687 0 0 0 4.265 7.4l-.38-.293a.375.375 0 0 1 0-.594l.38-.294a4.687 4.687 0 0 0 1.337-1.634l.186-.376Z"
        />
    </svg>
);
export default Script;
