import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import { ModalHeader, ModalSubtitle, ModalTitle } from 'components/ConfigOpportunityStageTrackingForm/styled';
import { Stage as PipelineStage } from 'data/firestore/agent/pipeline/stage/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import {
    ADD_EDIT_OPPORTUNITY_PIPELINE_STAGE_MODAL,
    CONFIG_OPPORTUNITY_PIPELINE_STAGE_TRACKING_MODAL,
} from '../../constants';

const mapDataToFormValues = (data: PipelineStage) => ({
    name: data.name,
    type: data.type,
    enableTracking: data.enableTracking,
    inactiveDaysThreshold: data.inactiveDaysThreshold,
});

const mapDataToConfigFormValues = (data: PipelineStage) => ({
    enableTracking: data.enableTracking,
    inactiveDaysThreshold: data.inactiveDaysThreshold,
});

const useManagePipelineStages = ({
    agentId,
    pipelineId,
    onPipelineStageCreated = () => {},
    onPipelineStageDeleted = () => {},
} : {
    agentId: string;
    pipelineId: string;
    onPipelineStageCreated?: (id: string) => void
    onPipelineStageDeleted?: () => void;
}) => {
    const [
        openPipelineStageForm,
        closePipelineStageForm,
    ] = useOpenModal(ADD_EDIT_OPPORTUNITY_PIPELINE_STAGE_MODAL);

    const [
        openPipelineStageConfigForm,
        closePipelineStageConfigForm,
    ] = useOpenModal(CONFIG_OPPORTUNITY_PIPELINE_STAGE_TRACKING_MODAL);

    const { mutateAsync: createPipelineStage } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/pipelines/${pipelineId}/stages`,
        onSuccess: closePipelineStageForm,
    });

    const { mutateAsync: updatePipelineStage } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closePipelineStageForm,
    });

    const { mutateAsync: reorderPipelineStage } = useHttpMutation({
        method: 'POST',
    });

    const { mutateAsync: deletePipelineStage } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreatePipelineStage = useCallback(
        (initialValues: Record<string, unknown> = {}) => openPipelineStageForm({
            title: <ModalTitle>Create pipeline stage</ModalTitle>,
            submitButtonLabel: 'Create',
            initialValues: {
                type: 'open',
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const { id } = await createPipelineStage({
                        body: values,
                    }) as PipelineStage;
                    onPipelineStageCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, createPipelineStage, onPipelineStageCreated, openPipelineStageForm],
    );

    const handleEditPipelineStage = useCallback(
        (data: PipelineStage) => openPipelineStageForm({
            id: data?.id,
            agentId,
            disabledFields: data.removable ? [] : ['name', 'type'],
            title: <ModalTitle>Edit pipeline stage</ModalTitle>,
            submitButtonLabel: 'Update',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const {
                    customerId, name, type, ...rest
                } = values;

                const update = (
                    data.removable
                        ? { name, type, ...rest }
                        : rest
                );
                showAppSpinner();
                try {
                    await updatePipelineStage({
                        pathname: `/agents/${agentId}/pipelines/${pipelineId}/stages/${data.id}`,
                        body: update,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, pipelineId, openPipelineStageForm, updatePipelineStage],
    );

    const handleDeletePipelineStage = useCallback(
        async (stageId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove pipeline stage?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deletePipelineStage({
                    pathname: `/agents/${agentId}/pipelines/${pipelineId}/stages/${stageId}`,
                });
                onPipelineStageDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, pipelineId, deletePipelineStage, onPipelineStageDeleted],
    );

    const handleReorderPipelineStage = useCallback(
        async (stageId: string, newPosition: number) => {
            try {
                await reorderPipelineStage({
                    pathname: `/agents/${agentId}/pipelines/${pipelineId}/stages/${stageId}/reorder`,
                    body: {
                        newPosition,
                    },
                });
                return true;
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            return false;
        },
        [agentId, pipelineId, reorderPipelineStage],
    );

    const StageSettingsHeader = () => (
        <ModalHeader>
            <ModalTitle>Stage Settings</ModalTitle>
            <ModalSubtitle>
                Set specific parameters for this stage. Define when opportunities
                are flagged as stuck based on how long they remain in this stage.
            </ModalSubtitle>
        </ModalHeader>
    );

    const handleConfigPipelineStage = useCallback(
        (data: PipelineStage) => openPipelineStageConfigForm({
            id: data?.id,
            agentId,
            title: <StageSettingsHeader />,
            submitButtonLabel: 'Save',
            initialValues: mapDataToConfigFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    await updatePipelineStage({
                        pathname: `/agents/${agentId}/pipelines/${pipelineId}/stages/${data.id}`,
                        body: values,
                    }) as PipelineStage;
                    showAppNotification({
                        icon: 'success',
                        title: 'Config updated',
                        description: 'Stage tracking config updated successfully',
                        timeout: 5000,
                    });
                    closePipelineStageConfigForm();
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [
            agentId,
            pipelineId,
            closePipelineStageConfigForm,
            openPipelineStageConfigForm,
            updatePipelineStage,
        ],
    );

    return {
        create: handleCreatePipelineStage,
        edit: handleEditPipelineStage,
        remove: handleDeletePipelineStage,
        reorder: handleReorderPipelineStage,
        config: handleConfigPipelineStage,
    };
};

export default useManagePipelineStages;
