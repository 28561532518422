import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import { OptionProps } from 'react-rainbow-components/components/Option';
import Picklist from 'components/Picklist';
import tryParseJson from 'data/services/string/parseJson';

type PickerOption = Pick<OptionProps, 'name' | 'label' | 'variant'> & {
    value?: string;
};

const transformToolsIntoOptions = (
    tools?: string | Record<string, unknown> | Record<string, unknown>[],
): PickerOption[] => {
    const json = (
        typeof tools === 'string'
            ? tryParseJson(tools)
            : tools
    );
    if (Array.isArray(json)) {
        return json.reduce(
            (result, tool) => {
                if (tool.name) {
                    return [
                        ...result,
                        {
                            name: tool.name,
                            label: tool.name,
                            value: tool.name,
                        },
                    ];
                }

                return result;
            },
            [] as PicklistValue,
        );
    }

    return [];
};

interface ToolPickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string;
    tools?: Record<string, unknown>[];
    onChange?: (value: string) => void;
}

const ToolPicker = (props: ToolPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        tools,
    } = useReduxForm(props);

    const options = useMemo(
        () => [
            {
                name: 'auto',
                label: 'Auto',
                value: 'auto',
            },
            {
                name: 'required',
                label: 'Required',
                value: 'required',
            },
            {
                name: 'none',
                label: 'None',
                value: 'none',
            },
            {
                name: '',
                label: 'Tools',
                variant: 'header',
            },
            ...transformToolsIntoOptions(tools),
        ] as PickerOption[],
        [tools],
    );

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps) as PicklistValue;
    }, [options, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map(({ variant = 'default', ...option }) => (
            <Option
                key={`tool-picker-option__${option.name}`}
                variant={variant as OptionProps['variant']}
                {...option}
            />
        )),
        [options],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            {pickListOptions}
        </Picklist>
    );
};

export default ToolPicker;
