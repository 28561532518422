import React from 'react';

export interface Context {
    trackedCalls: string[];
    addOutboundCall: (sessionSid: string) => void;
    dismissOutboundCall: (sessionSid: string) => void;
    dismissAllOutboundCalls: () => void;
}

const context = React.createContext<Context>({
    trackedCalls: [],
    addOutboundCall: () => {},
    dismissOutboundCall: () => {},
    dismissAllOutboundCalls: () => {},
});

export const { Provider, Consumer } = context;

export default context;
