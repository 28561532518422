import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 1.5rem;
    flex: false;
    padding: 0 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 1.5rem;
    background: #FADABB;
    color: #C18245;
    width: fit-content;
    cursor: default;
`;

export const TooltipContent = styled.div`
    display: flex;
    max-width: 20rem;
    padding: 0.75rem;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    text-align: left;
`;
