import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { Customer, ItemUser } from 'data/firestore/globals';
import ListTile from 'components/ListTile';
import {
    Container, Title, UserLabel,
} from './styled';
// import ListTile from '../ListTile';

interface CustomerInfoProps {
    customerData: ItemUser[];
}

const CustomersList = ({ customerData = [] }: { customerData: any[] }) => customerData.map(
    (user) => {
        const { id } = user as Customer;
        const { uid } = user as ItemUser;
        const displayName = getDisplayName(user);
        const initials = getInitials(displayName);
        const userId = uid || id || '';
        return (
            <ListTile
                icon={(
                    <UserAvatar
                        src={user.photoURL}
                        initials={initials}
                        value={userId}
                        size="small"
                    />
                )}
                title={<UserLabel>{displayName}</UserLabel>}
            />
        );
    },
);

const CustomerInfo = ({ customerData }: CustomerInfoProps) => (
    <Container>
        <Title>There are 2 customers with this phone number:</Title>
        <CustomersList
            customerData={(!Array.isArray(customerData) ? [customerData] : customerData)}
        />
    </Container>
);

export default CustomerInfo;
