import styled from 'styled-components';

export const StyledTitleContainer = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.35rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

export const PhoneNumber = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DirectionLabel = styled.span`
    margin-left: 0.5rem;
    text-transform: capitalize;
`;
