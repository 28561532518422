import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { composeValidators } from '@rainbow-modules/forms';
import { RenderIf } from 'react-rainbow-components';
import PipelineStageTypePicker from 'components/PipelineStageTypePicker';
import { validateInteger, validateNotEmpty } from 'data/validators';
import {
    FieldsContainer,
    StyledInput,
    Message,
    Row,
    StyledTextarea,
    StyledCheckBoxToggle,
} from './styled';

interface Params {
    message?: string;
    disabledFields?: string[],
}

const Fields = ({
    disabledFields = [],
    message = '',
}: Params) => {
    const { change } = useForm();
    const { values } = useFormState();

    useEffect(
        () => {
            if (values.enableTracking && !values.inactiveDaysThreshold) {
                change('inactiveDaysThreshold', 7);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [change, values.enableTracking],
    );

    return (
        <>
            <Message>{message}</Message>
            <FieldsContainer>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledInput}
                        name="name"
                        label="Stage Name"
                        labelAlignment="left"
                        placeholder="Enter a name for the stage"
                        borderRadius="semi-square"
                        autoComplete="off"
                        disabled={disabledFields.includes('name')}
                        required
                        validate={validateNotEmpty('This field is required')}
                    />
                </Row>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={PipelineStageTypePicker}
                        name="type"
                        label="Stage Type"
                        labelAlignment="left"
                        borderRadius="semi-square"
                        disabled={disabledFields.includes('type')}
                        required
                    />
                </Row>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledTextarea}
                        name="description"
                        label="Description"
                        labelAlignment="left"
                        borderRadius="semi-square"
                    />
                </Row>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledCheckBoxToggle}
                        name="enableTracking"
                        label="Enable Stage Tracking"
                        labelAlignment="left"
                    />
                </Row>
                <RenderIf isTrue={values.enableTracking}>
                    <Row>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={StyledInput}
                            type="number"
                            required
                            iconPosition="right"
                            icon="days"
                            name="inactiveDaysThreshold"
                            label="Time in Stage Threshold"
                            labelAlignment="left"
                            bottomHelpText="Enter the number of days an opportunity can remain in this stage before it is marked as stuck."
                            validate={composeValidators(
                                validateNotEmpty('Field is required'),
                                validateInteger('Must be a valid integer.'),
                            )}
                        />
                    </Row>
                </RenderIf>
            </FieldsContainer>
        </>
    );
};

export default Fields;
