import { isEmpty } from '@rainbow-modules/validation';

const tryParseFloat = (value: string = '') => {
    try {
        const numberStr = value.replaceAll(',', '');
        const result = parseFloat(numberStr);
        return result;
    } catch (error) {
        // no catch
    }

    return null;
};

export const validateNotEmpty = (
    message: string,
) => (value: unknown) => {
    if (isEmpty(value)) return message;
    return undefined;
};

export const validateNumber = (
    message: string,
) => (value: unknown) => {
    const numericValue = tryParseFloat(value as string);
    if (typeof value === 'string' && !isEmpty(value) && typeof numericValue !== 'number') {
        return message;
    }
    return undefined;
};

export const validateInteger = (
    message: string,
) => (value: string) => {
    if (!Number.isInteger(Number(value))) {
        return message;
    }
    return undefined;
};
