import { useRef } from 'react';
import { InternalTooltip, useDefaultTooltipConnector } from 'react-rainbow-components';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { Container, TooltipContent } from './styled';

interface OpportunityStuckBadgeProps {
    stuck?: Opportunity['stuck']
}

const OpportunityStuckBadge = ({ stuck }: OpportunityStuckBadgeProps) => {
    const { condition = 'unknown', description = '' } = stuck || {};
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef();
    const {
        onMouseEnter,
        onMouseLeave,
        isVisible,
    } = useDefaultTooltipConnector({
        tooltipRef,
        triggerRef: () => containerRef as any,
    });

    if (condition !== 'stalled') return null;

    return (
        <>
            <Container
                ref={containerRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                Stuck
            </Container>
            <InternalTooltip
                triggerElementRef={() => containerRef}
                isVisible={isVisible}
                preferredPosition="top"
                ref={tooltipRef}
            >
                <TooltipContent>
                    {description}
                </TooltipContent>
            </InternalTooltip>
        </>
    );
};

export default OpportunityStuckBadge;
