import { IconProps } from 'types';

const PhoneAnswered = ({ className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
    >
        {title && <title>{title}</title>}
        <path
            stroke="#19B332"
            strokeWidth={2}
            d="m3.19 8.616.002.009.003.008c.63 1.808 1.973 4.527 4.81 7.365 2.837 2.838 5.556 4.182 7.367 4.808l.007.002.007.003a3.77 3.77 0 0 0 3.857-.927c.472-.465.977-.97 1.347-1.34l.001-.001a1.398 1.398 0 0 0 0-1.975l-2.844-2.845-.707.707.707-.707a1.397 1.397 0 0 0-1.975 0l-1.382 1.382a.19.19 0 0 1-.227.032 18.048 18.048 0 0 1-5.296-5.303.187.187 0 0 1 .032-.219l.003-.004c.386-.389.955-.959 1.378-1.382a1.398 1.398 0 0 0 0-1.975l-.706.707.707-.707L7.437 3.41l-.708.707.708-.707a1.397 1.397 0 0 0-1.975 0L4.106 4.765a3.777 3.777 0 0 0-.917 3.851Z"
        />
        <path
            stroke="#19B332"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 4.5 17.333 7 22 2"
        />
    </svg>
);

export default PhoneAnswered;
