import { useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import CreateEditOpportunityStageForm from 'components/CreateEditOpportunityStageForm';
import ConfigOpportunityStageTrackingForm from 'components/ConfigOpportunityStageTrackingForm';
import KanbanBoard from 'components/KanbanBoard';
import { BoardColumn, CardMoveEvent, ColumnMoveEvent } from 'components/KanbanBoard/types';
import useManagePipelineStages from 'data/hooks/useManagePipelineStages';
import { ViewContextType } from 'pages/Opportunities/types';
import useManageOpportunities from '../../../../data/hooks/useManageOpportunities';
import { BoardContainer } from './styled';
import StageSummary from './summary';
import StageInfo from './stageInfo';
import EmptySearchMessage from './emptySearchMessage';
import useBoardStorage from './hooks/useBoardStorage';
import OpportunityCard from './OpportunityCard/index';

const OpportunitiesViewBoard = () => {
    const { agentId = '' } = useParams();
    const {
        selectedPipeline = '',
        opportunities = [],
        stages,
        isLoading,
        searchQuery,
        editColumnsMode,
    } = useOutletContext<ViewContextType>();

    const {
        collapsedColumns,
        expandColumn,
        collapseColumn,
    } = useBoardStorage({ pipelineId: selectedPipeline });

    const {
        create: createNewOpportunity,
        updateField: updateOpportunityField,
    } = useManageOpportunities({ agentId });

    const {
        create: createPipelineStage,
        edit: editPipelineStage,
        remove: removePipelineStage,
        reorder: reorderPipelineStage,
        config: configPipelineStage,
    } = useManagePipelineStages({
        agentId,
        pipelineId: selectedPipeline,
    });

    const boardColumns = useMemo(
        () => stages?.map((stage, index) => ({
            name: stage.id,
            label: stage.name,
            removable: stage.removable && !opportunities?.find(
                (opportunity) => opportunity.stageId === stage.id,
            ),
            draggable: stage.removable,
            editable: true,
            collapsed: collapsedColumns.includes(stage.name),
            position: index,
            data: stage,
        })),
        [collapsedColumns, opportunities, stages],
    );

    const cards = useMemo(
        () => opportunities?.map((opportunity) => ({
            id: opportunity.id,
            column: opportunity.stageId,
            title: opportunity.name,
            data: opportunity,
        })),
        [opportunities],
    );

    const handleAddNewOpportunity = useCallback(
        (columnName: string) => createNewOpportunity({
            pipeline: selectedPipeline,
            stageId: columnName,
        }),
        [createNewOpportunity, selectedPipeline],
    );

    const handleEditStageTracking = useCallback((columnName: string) => configPipelineStage(
        stages?.find((stage) => stage.id === columnName) as any,
    ), [configPipelineStage, stages]);

    const handleChangeOpportunityStage = useCallback(
        async ({ cardId, destinationColumn }: CardMoveEvent) => {
            try {
                await updateOpportunityField(cardId as string, 'stageId')(destinationColumn);
                return true;
            } catch (error) {
                // no catch
            }
            return false;
        },
        [updateOpportunityField],
    );

    const handleReorderPipelineStage = useCallback(
        async ({ columnId, destinationPosition }: ColumnMoveEvent) => {
            try {
                await reorderPipelineStage(columnId as string, destinationPosition);
                return true;
            } catch (error) {
                // no catch
            }
            return false;
        },
        [reorderPipelineStage],
    );

    const handleToggleColumn = useCallback(
        (column: BoardColumn) => (
            collapsedColumns.includes(column.label)
                ? expandColumn
                : collapseColumn
        )(column.label),
        [collapseColumn, expandColumn, collapsedColumns],
    );

    const handleAddNewColumn = useCallback(
        () => createPipelineStage({}),
        [createPipelineStage],
    );

    const handleEditColumn = useCallback(
        (column: BoardColumn) => editPipelineStage(
            stages?.find((stage) => stage.id === column.name) as any,
        ),
        [editPipelineStage, stages],
    );

    const handleDeleteColumn = useCallback(
        (column: BoardColumn) => removePipelineStage(column.name),
        [removePipelineStage],
    );

    return (
        <BoardContainer>
            <RenderIf isTrue={isEmpty(searchQuery) || opportunities.length > 0}>
                <KanbanBoard
                    isLoading={isLoading}
                    columns={boardColumns}
                    cards={cards}
                    cardComponent={OpportunityCard}
                    columnHeaderComponent={StageInfo}
                    columnFooterComponent={StageSummary}
                    onAddCardClick={handleAddNewOpportunity}
                    onSettingsClick={handleEditStageTracking}
                    onAddColumnClick={handleAddNewColumn}
                    onCardMoved={handleChangeOpportunityStage}
                    onColumnMoved={handleReorderPipelineStage}
                    onColumnCollapse={handleToggleColumn}
                    onColumnExpand={handleToggleColumn}
                    columnEditMode={editColumnsMode}
                    onRequestColumnEdit={handleEditColumn}
                    onRequestColumnDelete={handleDeleteColumn}
                />
            </RenderIf>
            <RenderIf isTrue={!isEmpty(searchQuery) && opportunities.length === 0}>
                <EmptySearchMessage query={searchQuery} />
            </RenderIf>
            <CreateEditOpportunityStageForm />
            <ConfigOpportunityStageTrackingForm />
        </BoardContainer>
    );
};

export default OpportunitiesViewBoard;
